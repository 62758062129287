.navbar {
	&-nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		font-size: 1rem;
	}
	&-item {
		& + & {
			margin-left: 0.65rem;
		}
	}
	&-mobile-toggler {
		position: relative;
		margin-right: -0.625rem;
		padding: 0.625rem;
		background-color: transparent;
		background-image: none;
		border: none;
		outline: none;
		border-radius: $border-radius;

		@include media-breakpoint-up(lg) {
			display: none;
		}
		& .icon-bar-box {
			position: relative;
			width: 1.5385rem;
			height: 1.5385rem;
		}
		& .icon-bar {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 2px;
			margin-top: -1px;
			background: var(--app-component-color);
			border-radius: $border-radius;
			transition: transform 0.2s ease-out;
			&:first-child {
				transform: translateY(-300%);
			}
			&:last-child {
				transform: translateY(300%);
			}
		}
		&:hover {
			background: none;
			opacity: 0.75;
		}
		@at-root .show-mobile-navbar & {
			.icon-bar:nth-child(2) {
				display: none;
			}
			.icon-bar:first-child {
				transform: rotate(-45deg);
			}
			.icon-bar:last-child {
				transform: rotate(45deg);
			}
		}
	}
}
