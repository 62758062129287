@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;600;700;900&display=swap');

html,
body {
	font-family: 'Noto Sans KR', sans-serif;
	font-size: 13px;
	color: var(--app-component-color);
	@include media-breakpoint-down(lg) {
		font-size: 12px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 11px;
	}
}

button {
	border: none;
	color: inherit;
}

a {
	color: var(--app-component-color);
	text-decoration: none;
}

.hidden {
	position: absolute;
	top: 0;
	left: 0;
	clip: rect(0, 0, 0, 0);
}

.required {
	color: $red;
}
