:root {
	--app-header-bg: #{$white};
	--app-header-color: #{$gray-900};

	--app-sidebar-bg: #{$gray-200};
	--app-sidebar-color: #{$gray-600};
	--app-sidebar-active-component-bg: #{$theme};
	--app-sidebar-hover-color: #{$gray-900};

	--page-padding-x: #{$page-padding};
	--app-contents-bg: #{$gray-300};

	--app-footer-color: #{$gray-600};
	--app-component-bg: #{$white};
	--app-component-color: #{$gray-900};
	--app-component-active-color: #{$black};
	--app-component-border-color: #{$gray-400};
	--app-component-disabled-bg: #{$gray-200};
	--app-component-disabled-color: #{$gray-400};

	--app-component-modal-bg: #{$white};
	--app-close-button-fillter: invert(0);

	--page-contents-bg: #{$gray-100};
	--page-contents-header-bg: #{tint-color($gray-200, 30%)};

	// 부트스트랩 override default
	--bs-body-color: var(--app-component-color);
	--bs-link-hover-color: inherit;
	--bs-link-color: inherit;

	@include media-breakpoint-down(sm) {
		--page-padding-x: #{$page-padding / 2};
	}
}

.dark-mode {
	--app-header-bg: #{shade-color($gray-800, 15%)};
	--app-header-color: #{$white};

	--app-sidebar-bg: #{$gray-900};
	--app-sidebar-color: #{$gray-500};
	--app-sidebar-active-component-bg: #{$theme};
	--app-sidebar-hover-color: #{$white};

	--app-contents-bg: #{shade-color($gray-900, 15%)};

	--app-component-bg: #{$gray-800};
	--app-component-color: #{$gray-100};
	--app-component-active-color: #{$white};
	--app-component-border-color: #{$gray-700};
	--app-component-disabled-bg: #{$gray-700};
	--app-component-disabled-color: #{$gray-600};

	--app-component-modal-bg: #{$gray-800};
	--app-close-button-fillter: invert(1) grayscale(100%) brightness(200%);

	--page-contents-bg: #{$gray-800};
	--page-contents-header-bg: #{shade-color($gray-800, 30%)};

	// 부트스트랩 override default
	--bs-body-color: var(--app-component-color);
	--bs-link-hover-color: inherit;
	--bs-link-color: inherit;
}
