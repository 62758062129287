$white: #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #2d353c !default;
$gray-900: #20252a !default;
$black: #000000 !default;

$skyblue: #348fe2 !default;
$navy: #4659ab !default;
$theme: #293896 !default;
$red: #ff5b57 !default;
$theme-colors: (
	'theme': $theme,
	'gray': $gray-700,
	'default': $gray-300,
	'navy': $navy,
	'skyblue': $skyblue,
	'red': $red,
	'green': $green,
);

// z-idnex
$app-header-zindex: 1050 !default;
$app-sidebar-zindex: 1000 !default;
$app-loader-zindex: 1100 !default;

// width
$app-sidebar-width: 16.9231rem !default;

// height
$app-header-height: 3.8462rem !default;
$app-footer-height-sm: 3.0769rem !default;

// padding
$page-padding: 2.3077rem !default;
$form-tag-padding-y: 0.4615rem !default;
$form-tag-padding-x: 0.9231rem !default;
