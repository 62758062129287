input[type='checkbox'],
select {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-transition: background-color 9999s ease-out;
	-webkit-box-shadow: inherit !important;
	-webkit-text-fill-color: var(--app-component-color) !important;
}

.form {
	&-content {
		display: inline-flex;
		align-items: center;
		position: relative;
		width: auto;
		@at-root .flex-1 & {
			width: 100%;
		}
	}
	&-label {
		margin-bottom: 0;
	}
	&-control {
		padding: $form-tag-padding-y $form-tag-padding-x;
		border-color: var(--app-component-border-color);
		background-color: var(--app-component-bg);
		font-weight: 400;
		&::placeholder {
			font-weight: 400;
			color: $gray-500;
		}
		&:focus {
			background-color: var(--app-component-bg);
		}
		&:disabled {
			background-color: var(--app-component-disabled-bg);
			color: var(--app-component-disabled-color);
		}
	}
	&-floating {
		& > .form-control {
			padding: $form-tag-padding-y $form-tag-padding-x;
			font-size: 1rem;
		}
		& > label {
			font-size: 1rem;
		}
	}
	&-select {
		padding: $form-tag-padding-y $form-tag-padding-x;
		padding-right: 1.75rem;
		border-color: var(--app-component-border-color);
		background-color: var(--app-component-bg);
		background-position-x: calc(100% - 0.4375rem);
		font-weight: 400;
		&:disabled {
			background-color: var(--app-component-disabled-bg);
			color: var(--app-component-disabled-color);
		}
		&-date {
			background-image: none;
			text-align: center;
		}
		@include media-breakpoint-down(sm) {
			background-size: 0.7692rem;
			background-position-y: 55%;
		}
		@at-root .dark-mode & {
			background-blend-mode: screen;
		}
	}
	&-check {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-end;
		margin: 0;
		&-label {
			cursor: pointer;
		}
		&-input {
			height: 1.3846rem;
			margin-right: 0.6154rem;
			border-color: $gray-500;
			background-color: $gray-500;
			&:checked {
				border-color: $skyblue;
				background-color: $skyblue;
			}
		}
	}
	&-switch &-check-input {
		&:focus {
			--bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
		}
	}

	&-custom {
		.col-form-label {
			background-color: var(--page-contents-header-bg);
		}
		.form-group {
			.form-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
	&-text {
		display: flex;
		align-items: center;
		height: 100%;
		margin: 0;
		padding: 0.5rem;
		font-size: inherit;
		color: inherit;
	}
	&-error {
		margin-bottom: 0;
		margin-top: 0.5rem;
		font-size: 0.9em;
		color: $danger;
	}
	.col-form {
		&-title {
			word-wrap: break-word;
		}
		&-tag {
			display: flex;
			// flex-wrap: wrap;
			padding-left: 1rem;
		}
	}
}

.form-floating {
	& > label::after {
		display: none;
	}
}

.form-search-group {
	margin-top: 0.75rem;
	.form-content:not(:first-child) {
		padding-left: 0;
	}
	@include media-breakpoint-down(sm) {
		.row {
			margin-right: -0.5rem;
		}
		.form-content {
			padding-right: 0.5rem;
		}
		.form-content:nth-child(2) {
			flex: 1;
			div {
				width: 100%;
			}
		}
		.btn {
			width: 29.66px;
			height: 29.66px;
			padding: 0;
		}
	}
}

// 등록/조회/수정 페이지 폼 태그
.form-info {
	.row {
		margin: 0;
	}
	.row > div {
		padding: 0;
		margin-top: -1px;
		border: 1px solid var(--app-component-border-color);
	}
	.form-grid {
		height: 100%;
	}
	.form-content {
		display: block;
	}
	.col-form-label {
		padding: 1rem;
		background-color: var(--page-contents-header-bg);
	}
	.col-form-box {
		height: 100%;
		padding: 0.5rem;
		background-color: var(--app-component-bg);
	}

	.form-control,
	.form-select {
		flex: 1;
	}
	.form-grid {
		.form-content {
			display: grid;
			width: 100%;
			height: 100%;
			padding: 0 !important;
		}
		.col-form-label,
		.col-form-tag {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}
	&.form-info-horizontal .form-content {
		.col-form-label {
			padding: 0.5rem;
			text-align: center;
		}
		.form-control,
		.form-select {
			width: 100% !important;
		}
	}

	@include media-breakpoint-up(sm) {
		.col-sm-6 .form-grid {
			.form-content {
				grid-template-columns: 33.3333% 66.66667%;
			}
		}
		.col-sm-12 .form-grid {
			.form-content {
				grid-template-columns: 16.66667% 83.3333%;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.col-md-6 .form-grid {
			.form-content {
				grid-template-columns: 33.3333% 66.66667%;
			}
		}
		.col-md-12 .form-grid {
			.form-content {
				grid-template-columns: 16.66667% 83.3333%;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.col-lg-6 .form-grid {
			.form-content {
				grid-template-columns: 33.3333% 66.66667%;
			}
		}
		.col-lg-12 .form-grid {
			.form-content {
				grid-template-columns: 16.66667% 83.3333%;
			}
		}
	}
}
