@mixin fontawesome() {
	font-family:
		Font Awesome\6 Free,
		Font Awesome\6 Pro,
		FontAwesome !important;
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
}
