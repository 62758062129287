#root {
	.rdt_Table {
		display: table;
		border: none;
		background-color: transparent;
		.rdt_TableHead {
			display: table-header-group;
			color: var(--app-component-color);
		}
		.rdt_TableBody {
			display: table-row-group;
		}
		.rdt_TableHeadRow,
		.rdt_TableRow {
			display: table-row;
			border: none;
			font-size: 1rem;
		}
		.rdt_TableRow {
			background-color: transparent;
			color: var(--app-component-color);
			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
			}
		}
		.rdt_TableCol,
		.rdt_TableCell {
			display: table-cell;
			width: auto;
			min-width: auto;
			padding: 0;
			text-align: center;
			cursor: pointer;
			&.sc-jJTsDX {
				width: 2.6154rem;
				padding: 0.7692rem;
			}
			> div {
				padding: 0.7692rem;
			}
		}
		.rdt_TableCol_Sortable {
			justify-content: center;
			align-content: center;
			> span {
				flex: 0;
				margin-right: 0;
				margin-left: 0.5rem;
				padding: 0;
				font-size: 0.6154rem;
			}
		}
		.rdt_TableHead {
			position: sticky;
			top: 0;
			z-index: 5;
		}
		.rdt_TableHeadRow {
			background-color: var(--app-sidebar-bg);
		}
		input[type='checkbox'] {
			width: 1.2308rem;
			height: 1.2308rem;
			margin-top: -1px !important;
			background-color: var(--app-component-bg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			border: 1px solid var(--app-component-border-color);
			appearance: none;
			@include transition($form-check-transition);
			@include border-radius($form-check-input-border-radius);

			&:checked {
				background-color: $navy;
				border-color: $navy;

				&[type='checkbox'] {
					@if $enable-gradients {
						background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$prefix}gradient);
					} @else {
						background-image: escape-svg($form-check-input-checked-bg-image);
					}
				}
			}

			&:disabled {
				pointer-events: none;
				filter: none;
				opacity: $form-check-input-disabled-opacity;
			}

			&[disabled],
			&:disabled {
				~ .form-check-label {
					cursor: default;
					opacity: $form-check-label-disabled-opacity;
				}
			}
		}
	}
}

.list-data-table {
	margin-top: 0.75rem;
	> div {
		display: block;
		overflow: auto;
		height: 100%;
		border: 1px solid var(--app-component-border-color);
	}
}
