.btn {
	padding: $form-tag-padding-y $form-tag-padding-x;
	white-space: nowrap;
	& + & {
		margin-left: 0.5rem;
	}
}

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		$bg: $value;
		$border: $value;
		$textColor: $white;
		$hoverBg: shade-color($value, 25%);
		$hoverBorder: shade-color($value, 25%);
		$hoverTextColor: $white;
		$activeBg: shade-color($value, 25%);
		$activeBorder: shade-color($value, 25%);
		$activeTextColor: $white;
		$disabledBg: tint-color($value, 25%);
		$disabledBorder: tint-color($value, 25%);
		$disabledTextColor: $white;
		
		@if $color == 'default' {
			$textColor: $gray-900;
			$hoverTextColor: $gray-900;
		}
		@include button-variant(
			$bg,
			$border,
			$textColor,
			$hoverBg,
			$hoverBorder,
			$hoverTextColor,
			$activeBg,
			$activeBorder,
			$activeTextColor,
			$disabledBg,
			$disabledBorder,
			$disabledTextColor
		);
	}
}

.dark-mode {
	@each $color, $value in $theme-colors {
		$shadeValue: shade-color($value, 50%);
		$textColor: $gray-200;
		.btn-#{$color} {
			$bg: $shadeValue;
			$border: $shadeValue;
			$textColor: $textColor;
			$hoverBg: tint-color($shadeValue, 15%);
			$hoverBorder: tint-color($shadeValue, 15%);
			$hoverTextColor: $textColor;
			$activeBg: tint-color($shadeValue, 15%);
			$activeBorder: tint-color($shadeValue, 15%);
			$activeTextColor: $textColor;
			$disabledBg: shade-color($shadeValue, 25%);
			$disabledBorder: shade-color($shadeValue, 25%);
			$disabledTextColor: shade-color($textColor, 25%);

			@include button-variant(
				$bg,
				$border,
				$textColor,
				$hoverBg,
				$hoverBorder,
				$hoverTextColor,
				$activeBg,
				$activeBorder,
				$activeTextColor,
				$disabledBg,
				$disabledBorder,
				$disabledTextColor
			);
		}
	}
}
