.page {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
	padding: 1.25rem var(--page-padding-x);

	@media screen and (max-width: 1023px) {
		overflow: scroll;
	}

	&-header {
		margin: 0 0 0.9231rem;
		padding: 0;
		border: none;

		@media screen and (max-width: 1023px) {
			position: relative;
		}

		&-title {
			position: absolute;
			font-size: 1.75rem;
			font-weight: 500;
			white-space: nowrap;
		}

		@include media-breakpoint-down(sm) {
			.page-header-title {
				font-size: 1.5rem;
			}
		}
	}
	&-contents {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		border-radius: 0.6154rem;
		box-shadow: none;
		background-color: var(--page-contents-bg);

		@media screen and (max-width: 1023px) {
			overflow: unset;
		}

		&-header {
			margin: 0;
			padding: 1rem;
			background-color: var(--page-contents-header-bg);
			font-size: 1rem;
			font-weight: 400;
			line-height: 1;
		}

		&-body {
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow: hidden;
			padding: 1rem;
		}
	}
}
